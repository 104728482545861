import React from 'react'
import { useTranslation } from 'react-i18next'
import { cx } from 'class-variance-authority'

import { Button, Input } from '@/components'
import { useAuthContext } from '@/context'
import { Loading } from '@/layouts'
import { useAuthSignInService } from '@/lib/hooks'

import styles from './LoginForm.module.scss'

const LoginForm: React.FC = () => {
  const [isError, setError] = React.useState(false)
  const [name, setName] = React.useState('')
  const [password, setPassword] = React.useState('')

  const { t } = useTranslation()
  const { setSession } = useAuthContext()

  const [{ value, loading }, signIn] = useAuthSignInService()

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()

    if (!name.trim() && !password.trim()) {
      setError(true)

      return
    }

    const response = await signIn(name, password)

    const { token } = response ?? {}

    if (token) {
      setName('')
      setPassword('')
      setSession(token)
    }
  }

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
    setError(false)
  }

  /**
   * Loader if:
   * - receiving a token
   * - get token and awaiting redirect
   */
  if (loading || value?.token) {
    return <Loading />
  }

  return (
    <div>
      <form className={cx([styles.form, 'box'])} onSubmit={handleSubmit}>
        <Input
          className={styles.input}
          label={t('LoginFormNameFieldLabel')}
          type="text"
          placeholder={t('LoginFormNameFieldPlaceholder')}
          value={name}
          required
          onChange={handleChangeName}
          errors={
            isError ? (t('LoginFormWrongNameMessage') as string) : undefined
          }
        />

        <Input
          className={styles.input}
          label={t('LoginFormPasswordFieldLabel')}
          type="password"
          placeholder={t('LoginFormPasswordFieldPlaceholder')}
          value={password}
          onChange={(event) => {
            setPassword(event.target.value)
          }}
        />

        <Button type="submit" className={styles.button} color="black">
          {t('LoginFormSigninButtonText')}
        </Button>
      </form>
    </div>
  )
}

export default LoginForm
